import $ from "jquery";
import {getCookie, setCookie} from "../helpers/cookie";
import Tooltip from "./tooltip";

const DAY = 60 * 60 * 24;
const ACTIVE_CLASS = '__show';
const COOKIE_NAME = 'basket';

export default class Basket {
  state = [];
  countItems = 0;
  basketEl = null;
  counterEl = null;
  tooltip = Tooltip;
  isMobile = false;

  constructor(isMobile = false) {
    const cookieValue = getCookie(COOKIE_NAME);
    this.state = cookieValue ? cookieValue.split(',').map(Number) : [];
    this.isMobile = isMobile;
    this.tooltip = new Tooltip(isMobile);
    this.triggerItems = $('.js_trigger_basket');
    this.removeItems = $('.js_remove_basket');
    this.basketAllBtnEl = $('.js_basket_add_all');
    this.basketEl = document.querySelector('.js_basket_bubble');
    this.counterEl = document.querySelector('.js_basket_counter');
    this.countItems = this.state.length;
    // mobile
    this.popoverSuccessEl = $('.js-popover-success');
    this.popoverCancelEl = $('.js-popover-cancel');

    this.init();
  }

  init() {
    if (this.basketEl) {
      this.setCounterValue();
      this.toggleVisibleBasket();
      this.attachEvents();
      this.state.map((el) => {
        $(`.js_trigger_basket[data-value="${el}"]`).addClass('__accept');
      });
    }
  }

  attachEvents() {
    const self = this;

    this.triggerItems.on('click', function () {
      const id = $(this).attr('data-value');
      const isAccept = $(this).hasClass('__accept');

      if (self.isMobile) {
        const isSalesOpen = !$('.hl_tooltip_wrapper').hasClass('__hidden');
        if (isSalesOpen) $('.hl_tooltip_wrapper').addClass('__hidden');
        if (isAccept) $('.hc_mobile_popover').removeClass('__initial');
        $('.hc_tooltip').toggleClass('__initial', !isAccept);
        self.tooltip.renderTextMobile(id);
        $('.hc_mobile_popover_wrapper').attr('data-id', id).removeClass('__hide');
      } else {
        $('.hc_tooltip').toggleClass('__initial');
        $(this).toggleClass('__accept');
        isAccept ? self.removeItem(+id) : self.addItem(+id);
      }
    });

    this.removeItems.on('click', function (e) {
      e.stopImmediatePropagation();
      const parentEl = $(this).parent();
      const id = parentEl.attr('data-value');

      $('.hc_tooltip').addClass('__initial');
      $(`.js_trigger_basket[data-value="${id}"]`).removeClass('__accept');
      parentEl.removeClass('__accept');
      hidePopover();
      self.removeItem(+id);
    });

    // mobile
    this.popoverSuccessEl.on('click', function () {
      const id = $(this).closest('.hc_mobile_popover_wrapper').attr('data-id');
      $(`.js_trigger_basket[data-value="${id}"]`).addClass('__accept');
      hidePopover();
      self.addItem(+id);
    });

    this.popoverCancelEl.on('click', function () {
      const id = $('.hc_mobile_popover_wrapper').data('id');
      $(`.js_trigger_basket[data-value="${id}"]`).removeClass('__accept');
      hidePopover();
      self.removeItem(+id);
    });

    this.basketAllBtnEl.on('click', this.addAll);

    document.addEventListener('basket:add', (e) => this.addItem(e.detail));
    document.addEventListener('basket:remove', (e) => this.removeItem(e.detail));
  }

  addItem(id) {
    if (!this.state.includes(id)) {
      this.state.push(id);
    }
    this.countItems = this.state.length;
    this.setCounterValue();

    if (this.countItems === 1) {
      this.toggleVisibleBasket();
    }

    this.updateItemsPopup();

    if (!this.isMobile) {
      this.tooltip.updateTooltipContent(id, true);
    }
  }

  removeItem(id) {
    this.countItems -= 1;
    this.state = this.state.filter(el => el !== id);
    this.setCounterValue();

    if (this.countItems === 0) {
      this.toggleVisibleBasket();
      $.fancybox.close();
    }

    if (this.countItems < 10 && this.basketAllBtnEl.hasClass('__hidden')) {
      this.basketAllBtnEl.removeClass('__hidden');
    }
    this.updateItemsPopup();

    if (!this.isMobile) {
      this.tooltip.updateTooltipContent(id, false);
    }
  }

  setCounterValue() {
    if (this.counterEl) {
      this.counterEl.innerHTML = this.countItems;
    }
  }

  toggleVisibleBasket() {
    const isShow = this.countItems > 0;
    if (this.basketEl) {
      this.basketEl.classList.toggle(ACTIVE_CLASS, isShow);
    }
  }

  addAll = () => {
    this.state = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    this.countItems = this.state.length;
    this.triggerItems.addClass('__accept');
    this.basketAllBtnEl.addClass('__hidden');
    $('.js_basket_popup_item').addClass('__accept');
    this.setCounterValue();
    this.updateItemsPopup();
  }

  updateItemsPopup = () => {
    $('#bp-selected-items').val(`[${this.state}]`);
    setCookie(COOKIE_NAME, this.state, {secure: true, 'max-age': DAY});
  }
}

function hidePopover() {
  $('.hc_mobile_popover_wrapper').addClass('__hide');
  setTimeout(() => {
    $('.hc_mobile_popover').addClass('__initial');
  }, 300);
}
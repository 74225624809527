import $ from "jquery";
import tippy from 'tippy.js';

export const TooltipTextEnum = Object.freeze({
  1: '80% der Umsätze werden von 20% von Kunden generiert. Diese 20% Kunden müssen ideal betreut werden. Veranlassen Sie den Key Account Management an Mr.Bridge, damit die Kundenloyalität und Umsätze kontinuierlich steigen',
  2: 'In Ihrem Auftrag halten wir Kundentreffen - und Präsentationen ab und erfassen anschließend Berichte',
  3: 'Wir analysieren Ihren Zielmarkt, inklusive Wettbewerbsanalyse, ermitteln Kundenbedürfnisse, sowie die Marktmechanismen und verschaffen somit eine solide Grundlage für einen effizienten Vertrieb',
  4: 'Sowohl bei einer bestehenden Vertriebsstrategie als auch bei der Neuentwicklung, steigen wir gerne ein um mit einem kompetenten und unabhängigen Blick das beste Vertriebsergebnis zu erzielen.',
  5: 'Wir finden für Sie neue Kunden mit effizienten und maßgeschneiderten Mitteln. Von Kaltakquise (Telemarketing) bis auf hochkomplexe staatliche Aufträge, mit Mr. Bridge kommen Sie an Ihr Vertriebsziel. ',
  6: 'Sie möchten einen Area Sales Man für GUS-Staaten, Osteuropa, Middle East oder Nordafrika anstellen? Sparen Sie Ihr Geld und profitieren von Mr. Bridge Service.',
  7: 'Sie sind für den neuen Markt bereit oder wollen auf bestehenden Märkten stärker werden?  Dann nutzen Sie unseren Service mit Fragebogen um zu verstehen was nötig ist!',
  8: '',
  9: '„Wenn Sie heute dasselbe machen, was Sie gestern gemacht haben, dann verlieren Sie Ihr Geschäft“, sagte einmal Philipp Kotler. Das heutige Leben ist im Wandel und wir kommen kaum den Neuentwicklungen, sowie ständig ändernden Kundenbedürfnissen nach.  Dies ist auch der Grund, warum unser Mr. Bridge entstanden ist 😉',
  10: 'Sie möchten Ihre Präsenz auf der Weltkarte erweitern und sind noch nicht bereit Geld in den Geschäftsaufbau und Büromiete zu investieren, dann lassen Sie uns Ihre Zielvorhaben besprechen.',
});

export default class Tooltip {
  isMobile = false;
  tooltipDescElements = null;
  tooltipMobileWrapper = null;
  tippyInstanceArray = [];

  constructor(isMobile = false) {
    this.isMobile = isMobile;
    this.tooltipDescElements = $('.js_tooltip_show');
    this.tooltipMobileWrapper = $('.js_tooltip_mobile');

    this.init();
  }

  init() {
    if (!this.isMobile) {
      this.attachEvents();
      setTimeout(() => {
        this.setTooltipContentDesc();
      }, 1);
    }
  }

  attachEvents() {
    if (!this.isMobile) {
      this.tooltipDescElements.mouseover(function (e) {
        e.stopImmediatePropagation();
        $(this).closest('.hcl_item').find('.item_number').addClass('__hover');
        $(this).addClass('__hover');
      }).mouseout(function() {
        $(this).closest('.hcl_item').find('.item_number').removeClass('__hover');
        $(this).removeClass('__hover');
      });
    }
  }

  renderTextMobile(id) {
    this.tooltipMobileWrapper.find('.hct_text').html(TooltipTextEnum[id]);
  }

  renderContentTooltipDesc(id, isAccept) {
    return `
    <div class="hc_tooltip ${isAccept ? '' : '__initial'}">
      ${TooltipTextEnum[id] ? `<p class="hct_text">${TooltipTextEnum[id]}</p>` : ''} 
      <button type="button" class="btn success fullWidth" data-type="success">Wählen Sie einen Dienst aus</button>
      <button type="button" class="btn secondary-transparent fullWidth" data-type="cancel">Abwählen</button>
    </div>
    `;
  }

  setTooltipContentDesc() {
    const self = this;

    if (this.tooltipDescElements) {
      this.tooltipDescElements.map(function () {
        const id = +$(this).attr('data-value');
        const isAccept = $(this).closest('.js_trigger_basket').hasClass('__accept');
        self.tippyInstanceArray.push(tippy($(this).get(0), {
          content: self.renderContentTooltipDesc(id, isAccept),
          interactive: true,
          arrow: false,
          theme: 'custom',
          allowHTML: true,
          placement: 'right-end',
          maxWidth: 240,
          interactiveBorder: 20,
          hideOnClick: false,
          onShow: () => {
            self.attachHandleButton();
          },
        }));
      });
    }
  }

  attachHandleButton = () => {
    const self = this;

    setTimeout(function () {
      $('[data-type="success"]').on('click', function (e) {
        e.stopImmediatePropagation();
        const parentEl = $(this).closest('.js_trigger_basket');
        const id = parentEl.attr('data-value');
        $('.hc_tooltip').removeClass('__initial');
        parentEl.addClass('__accept');
        self.dispatchEvent('basket:add', +id);
      });

      $('[data-type="cancel"]').on('click', function(e) {
        e.stopImmediatePropagation();
        const parentEl = $(this).closest('.js_trigger_basket');
        const id = parentEl.attr('data-value');
        $('.hc_tooltip').addClass('__initial');
        parentEl.removeClass('__accept');
        self.dispatchEvent('basket:remove', +id);
      });
    }, 1);
  }

  updateTooltipContent(id, isAccept) {
    this.tippyInstanceArray[id - 1].setContent(this.renderContentTooltipDesc(id, isAccept));
  }

  dispatchEvent(type, id) {
    document.dispatchEvent(new CustomEvent(type, {detail: id}));
  }
}
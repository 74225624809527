import $ from "jquery";

const LANG = 'de';

export default class SelectCountry {
  selected = [];
  loadOptions = {};

  optionsEl = null;
  textHelpEl = null;
  selectedWrapper = null;

  constructor() {
    this.optionsEl = $('.js_sc_options');
    this.selectedWrapper = $('.js_sc_selected');
    this.textHelpEl = $('.js_sc_text_help');

    this.init();
  }

  init() {
    if ($('.js_sc_wrapper')) {
      this.loadOptionsJson();
      this.attachEvents();
    }
  }

  loadOptionsJson() {
    const self = this;
    readTextFile(`./json/country_${LANG}.json`, function(text){
      self.loadOptions = JSON.parse(text);
      for (const [key, value] of Object.entries(self.loadOptions)) {
        self.optionsEl.append(`<option value="${key}">${value}</option>`);
      }

      setTimeout(() => {
        $(self.optionsEl).each(self.prepareSelectBox);
      }, 1);
    });
  }

  attachEvents() {
    document.addEventListener('select:add', (e) => this.handleAdd(e.detail));
    document.addEventListener('select:remove', (e) => this.removeItem(e.detail));
  }

  prepareSelectBox() {
    var $this = $(this), numberOfOptions = $(this).find('option').length;

    $this.addClass("select-hidden");
    $this.wrap("<div class=\"select\"></div>").parent();
    $this.after("<div class=\"select-styled\"></div>");

    var $styledSelect = $(this).parent().find(".select-styled");
    $styledSelect.text($this.find("option").eq(0).text());

    var $list = $("<ul />", {
      "class": "select-options",
    }).insertAfter($styledSelect);

    for (var i = 1; i < numberOfOptions; i++) {
      $("<li />", {
        text: $this.find("option").eq(i).text(),
        rel: $this.find("option").eq(i).val(),
      }).appendTo($list);
    }

    var $listItems = $list.find("li");

    $styledSelect.click(function (e) {
      e.stopPropagation();
      $("div.select-styled.active").not(this).each(function () {
        $(this).removeClass("active").next("ul.select-options").hide();
      });
      $(this).toggleClass("active").next("ul.select-options").toggle();
    });

    $listItems.on('click', function (e) {
      e.stopPropagation();
      if (!$(this).attr('rel')) {
        return;
      }
      const value = $(this).attr("rel");
      const textValue = $(this).text();

      $styledSelect.text(textValue).removeClass("active");
      $this.val(value);
      $(this).parent().find(".active").removeClass("active");
      $(this).addClass("active");
      $this.addClass("active");
      $list.hide();
      document.dispatchEvent(new CustomEvent('select:add', {detail: {value, textValue}}));
    });

    $(document).click(function (e) {
      if ($styledSelect.hasClass('active')) {
        const excludedHideEl = ['mCustomScrollBox', 'mCSB_dragger', 'mCSB_dragger_bar']
        if (excludedHideEl.some(el => $(e.target).attr('class').includes(el))) {
          return;
        }
        $styledSelect.removeClass("active");
        $list.hide();
      }
    });
  }

  handleAdd(item) {
    this.selected.push(item.value);
    $('#selected_country').val(`${JSON.stringify(this.selected)}`);
    this.selectedWrapper.append(`
    <div class="bsi_item js_sc_item" data-key="${item.value}">
      <span class="bsi_text">
        <span class="item_value">${item.textValue}</span>
      </span>
      <span class="item_close_btn js_sc_item_remove">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6777 13.364C12.0682 13.7545 12.7014 13.7545 13.0919 13.364C13.4824 12.9734 13.4824 12.3403 13.0919 11.9497L8.14231 7.00017L13.0921 2.05043C13.4826 1.6599 13.4826 1.02674 13.0921 0.636212C12.7015 0.245687 12.0684 0.245688 11.6778 0.636212L6.72809 5.58596L1.77817 0.636039C1.38765 0.245515 0.754486 0.245514 0.363961 0.636039C-0.0265637 1.02656 -0.026563 1.65973 0.363961 2.05025L5.31388 7.00017L0.364134 11.9499C-0.0263907 12.3404 -0.026391 12.9736 0.364134 13.3641C0.754658 13.7547 1.38782 13.7547 1.77835 13.3641L6.72809 8.41439L11.6777 13.364Z" fill="currentColor"></path>
        </svg>
      </span>
     </div>`
    );

    this.textHelpEl.removeClass('__hidden');
    this.selectedWrapper.removeClass('__hidden');

    $('.js_sc_item_remove').off('click', (e) => this.handleRemove(e, this.callbackRemove));
    $('.js_sc_item_remove').on('click', (e) => this.handleRemove(e, this.callbackRemove));
  }

  handleRemove(e, callback) {
    const key = $(e.target).closest('.js_sc_item').attr('data-key');
    callback(key);
    $(`.js_sc_item[data-key=${key}]`).remove();
  }

  callbackRemove = (key) => {
    this.selected = this.selected.filter(el => el !== key);
    if (this.selected.length === 0) {
      this.textHelpEl.addClass('__hidden');
      this.selectedWrapper.addClass('__hidden');
      $('.select-options').find(`li[rel="${key}"]`).removeClass('active');
      $('.select-styled').html('Wählen Sie ein Land aus');
    }
    $('#selected_country').val(`${JSON.stringify(this.selected)}`);
  }
}

function readTextFile(file, callback) {
  var rawFile = new XMLHttpRequest();
  rawFile.overrideMimeType("application/json");
  rawFile.open("GET", file, true);
  rawFile.onreadystatechange = function() {
    if (rawFile.readyState === 4 && rawFile.status == "200") {
      callback(rawFile.responseText);
    }
  };
  if (rawFile) {
    rawFile.send(null);
  }
}
